.sign-up-main-div {
  display: flex;
  justify-content: center;
  background-color: #3b5d50 !important;
}

.sign-up-box-div {
  background: #dfdfdf;
  width: 100%;
  max-width: 500px;
  padding: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sign-up-text {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 25px;
  text-align: center;
}

.sign-up-text-field-div {
  margin-bottom: 25px;
}

.sign-up-button {
  width: 100%;
  margin-bottom: 25px;
}

.sign-up-forgot-text {
  text-align: center;
  margin: 0;
}

.sign-up-text-fields {
  display: flex;
  gap: 40px;
}

.form-label {
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 600;
}

.signup-have-account-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-have-account-text {
  margin: 0;
}

.signup-login-text {
  color: #000;
}