:root{
  --main-bg:#52a447;
}
header{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: sticky;
    top: 0;
    z-index: 9999;
}
.header-main-container {
  background: #52a447 !important;
  width: 100%;
}

.header-image-logo {
  width: 100%;
  height: 50px;
}

.header-container {
  width: 100% !important;
  max-width: 1300px !important;
}
.navbar-menu-item.navbar-nav {
  transition: all 0.5s ease-in-out;
}
.header-right-section {
  display: flex;
  width: 100%;
  max-width: 100px;
  align-items: center;
  gap: 13px;
  justify-content: center;
}
.navbar {
  --bs-navbar-toggler-icon-bg: url("../image/hamburger-icon.png") !important;
}
.hero-slider-desktop-image {
  display: block;
  /* height: 585px; */
  width: 100%;
  margin: auto;
}
.navbar-toggler-icon {
  width: 1.2em;
  height: 1.4em;
}

.hero-slider-mobile-image {
  display: none;
}

.navbar-menu-item {
  display: flex;
  gap: 20px;
}
button:focus:not(:focus-visible) {
  outline: none !important;
  box-shadow: none;
}
.navbar-text-items {
  color: #ffffff !important;
  text-decoration: none;
  font-size: 17px;
  font-weight: 500;
}

.navbar-icon {
  color: #ffffff !important;
  font-size: 18px;
}

.category {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin: 50px;
}

.category-menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.menu-items {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.category-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.product-category-main-container {
  margin-bottom: 50px;
}

.product-category-image-banner {
  width: 100%;
  display: block;
  height: 400px;
  object-fit: cover;
}

.product-category-image-banner-responsive {
  display: none;
}

.product-category-title-section {
  /* display: flex; */
  /* justify-content: space-between; */
  text-align: center;
  width: 100%;
  max-width: 1150px;
  margin: 50px auto;
}

.product-category-button {
  float: inline-end;
  margin-right: 25px;
}

.product-category-title {
  font-size: 35px;
  font-weight: 700;
  color: #e8264b;
}

.product-category-viewall-button {
  background: #e8264b !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  border: none !important;
}

.cards {
  gap: 20px;
}

.card-body {
  padding: 0px !important;
}

.list-group-item {
  padding: 10px !important;
}

.common-cards {
  padding: 16px;
  display: flex;
  gap: 15px;
  box-shadow: 0 0 10px 3px #0000000d;
}

.highlight-text {
  font-weight: 700;
}

.top-title {
  background: yellow;
  padding: 5px;
  border-radius: 15px;
  text-align: center;
}

.product-btn-div {
  position: relative;
  height: 50px;
}

.add-to-cart-product-btn {
  text-align: center;
  width: 100%;
  background: #e8264b !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  border: none !important;
  position: absolute;
  bottom: 0;
}

.product-category-card-main {
  /* display: flex; */
  position: relative;
  width: 100%;
  overflow: hidden;
}

.product-category-card-main {
  width: 100%;
  margin: auto;
  max-width: 1250px;
  gap: 10px;
}

.common-cards.card {
  border-radius: 20px;
  box-shadow: 0 0 10px 3px #0000000d;
  height: 100%;
}

.product-card {
  flex: 0 0 100%;
  box-sizing: border-box;
  padding: 16px;
  border: 1px solid #ddd;
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}

.product-card.active {
  opacity: 1;
}

.arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 8px;
  cursor: pointer;
}

.arrow-button.left {
  left: 0;
}

.arrow-button.right {
  right: 0;
}

.hair-care-product-category-viewall-button {
  background: #805686 !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  border: none !important;
}

.hair-care-product-category-title {
  font-size: 35px;
  font-weight: 700;
  color: #805686;
}

.hair-care-product-category-btn {
  text-align: center;
  width: 100%;
  background: #805686 !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  border: none !important;
  position: absolute;
  bottom: 0;
}

.hair-care-category-logo-container {
  width: 100%;
  margin-top: 60px;
}

.hair-care-category-logo-section {
  display: flex;
  justify-content: center;
}

.hair-care-logo-main {
  width: 165px;
}

.hair-care-category-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hair-care-logo {
  max-width: 65px;
}

.hair-care-category-logo-text {
  text-align: center;
}

.wellness-product-category-title {
  font-size: 35px;
  font-weight: 700;
  color: #9fc034;
}

.wellness-product-category-viewall-button {
  background: #9fc034 !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  border: none !important;
}

.welness-product-category-btn {
  text-align: center;
  width: 100%;
  background: #9fc034 !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  border: none !important;
  position: absolute;
  bottom: 0;
}

.body-care-product-category-title {
  font-size: 35px;
  font-weight: 700;
  color: #e9812a;
}

.body-care-product-category-viewall-button {
  background: #e9812a !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  border: none !important;
}

.body-care-product-category-btn {
  text-align: center;
  width: 100%;
  background: #e9812a !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  border: none !important;
  position: absolute;
  bottom: 0;
}

.fragrance-product-category-title {
  font-size: 35px;
  font-weight: 700;
  color: #eb264e;
}

.fragrance-product-category-viewall-button {
  background: #eb264e !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  border: none !important;
}

.fragrance-product-category-btn {
  text-align: center;
  width: 100%;
  background: #eb264e !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  border: none !important;
  position: absolute;
  bottom: 0;
}

footer {
  width: 100%;
  background: var(--main-bg);
  padding: 60px 0;
}
.footer-div-section {
  display: flex;
  justify-content: center;
  padding: 25px 0px;
  align-items: center;
  gap: 50px;
}

.footer-logo {
  width: 250px;
  margin-bottom: 10px;
}

.footer-logo-content {
  text-align: center;
}

.footer-logo-section-title {
  color: white;
  font-size: 23px;
  margin-bottom: 15px;
  text-decoration: underline;
}

.footer-list-items {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-list-items li{
  margin-bottom: 10px;
}
.footer-link {
  color: #ffffff;
  text-decoration: none;
}

.footer-right-section {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
}

.footer-right-section-icon {
  font-size: 25px;
  color: white;
}

.footer-right-section-text {
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 1px;
  color: white;
  margin: 0;
}


@media(max-width:991px){
  .navbar-menu-item.navbar-nav {
  position: absolute;
  width: 100%;
  top: 63px;
  background: var(--main-bg);
  left: 0;
  padding: 15px 30px 15px;
} 
.navbar-toggler-icon {

  width: 1.1em !important;
  height: 1.3em !important;
}
.navbar-icon {
  font-size: 18px;
}
.header-right-section {
  gap: 5px;
  justify-content: unset;
}
button.navbar-toggler.collapsed {
  padding: 3px 10px;
}
}