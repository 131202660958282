.login-main-div {
  display: flex;
  justify-content: center;
  background: #f7f7f7;
}

.login-box-div {
  background: #dfdfdf;
  padding: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.login-text {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 25px;
}

.login-text-field-div {
  margin-bottom: 25px;
}

.login-button {
  width: 100%;
  margin-bottom: 25px;
}

.login-forgot-text {
  text-decoration: none;
  color: #000;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.login-form-lable {
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 600;
}

.login-new-customer-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-new-customer-text {
  margin: 0;
}

.login-new-create-acc-text {
  color: #000;
}