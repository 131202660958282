.product-category-listing-div {
  margin: 50px 0px;
}

.hero-section {
  width: 100%;
  max-width: 1400px;
  height: 250px;
  background-color: #3b5d50 !important;
  margin: 2px 0px;
  display: flex;
  align-items: center;
  padding-left: 55px;
  color: #ffffff;
}
button.slick-arrow {
  display: none !important;
}