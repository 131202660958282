:root{
  --main-bg:#52a447;
}
.cart-main-container {
  width: 100%;
  max-width: 1150px;
  margin: auto;
  padding: 50px 0px;
}

.cart-title-main {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin: 30px 0px;
}

.cart-table-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  border-top: 1px solid #c1c1c1;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0px;
}

.cart-product-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.cart-qty-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.cart-qty-div button {
  background-color: var(--main-bg) !important;
  border: none;
  width: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-qty-count {
  margin: 0;
  font-size: 25px;
  font-weight: 600;
}

.cart-price-text {
  margin: 0;
  font-size: 25px;
  font-weight: 600;
}

.cart-remove-product-section {
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.cart-remove-product-section svg{
  fill: red;
}
.cart-summary-section input {
  background-color: #52a44745;
  border: 1px solid var(--main-bg);
  height: 32px;
  border-radius: 5px;
  outline: none;
  text-align: center;
  width: 60%;
}
.cart-summary-section button {
  background-color: var(--main-bg);
  border: none;
  color: #fff;
  margin: 0 10px;
  padding: 4px 17px;
  border-radius: 5px;
  cursor: pointer;
}
.cart-summary {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  border-bottom: 1px solid #c1c1c1;
  padding: 10px;
}

.cart-summary-sub-total {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 20px;
  font-weight: 400;
  border-bottom: 1px solid #c1c1c1;
}

.cart-summary-discount {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 20px;
  font-weight: 400;
  border-bottom: 1px solid #c1c1c1;
}

.cart-summary-total-price {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 25px;
  font-weight: 700;
}

.cart-check-out-btn {
    width: 100%;
  margin-left: auto;
  background: var(--main-bg) !important;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-section {
  width: 100%;
  max-width: 1400px;
  height: 250px;
  background-color: #3b5d50 !important;
  margin-top: 2px;
  display: flex;
  align-items: center;
  padding-left: 55px;
  color: #ffffff;
}

/* ________________________________________________________________________________________________________________ */

.table-head-div {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.table-head-div tr th {
  width: 200px;
}

.table-row-div{
  text-align: center;
  vertical-align: middle;
}

