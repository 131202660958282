.single-product-main-div {
  width: 100%;
  /* max-width: 1400px; */
  margin: auto;
}

.single-product-image-main {
  width: 100%;
}

.single-product-image {
  width: 100%;
}

.single-product-detail-section-main {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px dotted;
}

.single-product-detail-content-title {
  font-size: 40px;
  font-weight: 700;
}

.single-product-detail-content-divider {
  border-bottom: 2px solid black;
  margin-bottom: 10px;
}

.single-product-detail-content-text {}

.icon-section {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.images-section {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.single-product-buy-section-main {
  width: 100%;
  max-width: 1200px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  gap: 50px;
}

.single-product-buy-product-image {}

.single-product-buy-product-image-slider-div {
  display: flex;
  gap: 30px;
}

.single-product-buy-product-image-slider-thumbnail {}

.single-product-buy-product-image-slider-thumbnail-img {
  margin-bottom: 20px !important;
}

.single-product-price-card-div {
  display: flex;
  justify-content: center;
  gap: 50px;
  /* margin: 30px;   */
}

.single-product-price-card {
  background: #aa2d3f;
  border-radius: 25px;
  padding: 15px 7px;
  /* font-size: 20px; */
  /* font-weight: 700; */
  color: #ffffff;
  display: flex;
  align-items: center;
}

.single-product-price-card-section {
  width: 100%;
}

.product-prise {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

.product-prise-time {
  text-align: center;
  font-size: 15px;
  font-weight: 700;
}

.single-product-button {
  background-color: #aa2d3f !important;
  border: none !important;
  font-size: 20px !important;
  margin: 20px 0px;
}

.product-btns {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.product-description-title {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

/* .product-discription {
  line-height: 25px;
  font-size: 20px;
} */

/* .product-des-div {
  margin: 35px 0;
} */

.product-des-div p {
  margin: 0;
  white-space: pre-line;
}

.product-discription-span {
  font-size: 15px;
  font-weight: 700;
}

.single-product-discription-main {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.single-product-icon-section-main {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.single-product-icon-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-product-use-this-section {
  width: 100%;
  max-width: 1180px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
}

.single-product-hero-section {
  width: 100%;
  max-width: 1400px;
  height: 250px;
  background-color: #3b5d50 !important;
  margin-top: 2px;
  display: flex;
  align-items: center;
  padding-left: 55px;
  color: #ffffff;
}

.slider .slick-prev:before,
.slider-vertical .slick-next:before {
  display: none;
}

.slider .slick-prev,
.slider .slick-next {
  width: 28px;
  height: 28px;
  border-style: solid;
  margin: 10px;
  display: inline-block;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  cursor: pointer;
}

.slider .slick-prev:before,
.slider .slick-next:before {
  display: none;
}

.slider .slick-prev {
  border-width: 14px 14px 14px 0px;
  border-color: transparent #ffffff;
  left: 0px;
  right: auto;
}

.slider .slick-next {
  border-width: 14px 0px 14px 14px;
  border-color: transparent #ffffff;
  right: 0px;
  left: auto;
}

.slider .slick-prev:hover,
.slider .slick-next:hover {
  border-color: transparent #ffd200;
}

.slider .slick-prev.slick-disabled,
.slider .slick-next.slick-disabled,
.slider .slick-prev.slick-disabled:hover,
.slider .slick-next.slick-disabled:hover {
  opacity: 0.1;
  cursor: default;
  border-color: transparent #000000;
}

.slider div {
  height: 250px;
  background: #333333;
  text-align: center;
  font-size: 75px;
  color: #ffffff;
}

.slider-thumb div {
  height: 100px;
  line-height: 50px;
  font-size: 25px;
}

.slider-thumb .slick-slide.slick-current {
  background: green;
}

.slick-slide img {
  margin: 0 auto;
}

ul.slick-dots.slick-thumb {
  position: absolute;
  left: 0;
  top: 0;
  flex-direction: column;
  display: flex !important;
}

.slick-dots li {
  margin-bottom: 85px !important;
}

.product-main-img {
  width: 500px;
  margin: auto;
}

@media (max-width: 1024px) {
  ul.slick-dots.slick-thumb {
    justify-content: center;
  }

  .slick-slide img {
    margin-left: 100px;
    width: 75%;
    object-fit: contain;
    height: 100%;
  }

  .slick-dots li {
    margin-bottom: 60px !important;
  }

  .slick-dots li a img {
    justify-content: center;
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 767px) {
  .slick-slide img {
    margin-left: auto;
    width: 50%;
    object-fit: contain;
    height: 100%;
  }

  ul.slick-dots.slick-thumb {
    bottom: -20px;
    flex-direction: row;
    top: auto;
  }

  .single-product-price-card-section {
    width: 100%;
    margin-top: 120px;
  }

  .slick-dots li {
    margin: 0 50px 0 0 !important;
  }
}

@media (max-width: 575px) {
  .slick-slide img {
    margin: 0 auto;
  }

  .product-main-img {
    width: 100%;
    margin: auto;
  }
}

@media (max-width: 330px) {

  .slick-dots li a img {
    display: none !important;
  }

  .slick-slide img {
    width: 90%;
  }
}

/* START MODULE STYLING */
.module-gallery {
	width: 100%;
}
.module-gallery .slider-wrapper {
	display: flex;
	overflow: hidden;
	max-height: 500px
}
.module-gallery .slider-thumb {
	max-width: 125px;
}
.module-gallery .slider-thumb li {
	max-height: 100px;
}
/* .module-gallery .slider-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
} */
.module-gallery .slider-thumb .slick-prev,
.module-gallery .slider-thumb .slick-next {
	z-index: 1;
	height: 1em;
	width: 100%;
	background-color: #000;
	opacity: 0.3;
	transition: opacity 300ms ease;
	font-size: 1.5em;
	color: #fff;
}
.module-gallery .slider-thumb .slick-prev:before,
.module-gallery .slider-thumb .slick-next:before {
	content: '';
}
.module-gallery .slider-thumb .slick-prev:hover,
.module-gallery .slider-thumb .slick-next:hover {
	opacity: 0.5;
}
.module-gallery .slider-thumb .slick-prev {
	left: unset;
	top: 0.5em;
}
.module-gallery .slider-thumb .slick-next {
	top: unset;
	right: unset;
	bottom: -0.5em;
}

.slick-track .slick-track { display: none; }


@media (max-width: 767px) {
	.module-gallery .slider-wrapper {
		flex-direction: column-reverse;
	}
	.module-gallery .slider-thumb {
		max-width: 100%;
	}
	.module-gallery .slider-thumb .slick-prev,
	.module-gallery .slider-thumb .slick-next {
		top: 50%;
		width: 1em;
		height: 100%;
	}
	.module-gallery .slider-thumb .slick-prev {
		left: 0;
	}
	.module-gallery .slider-thumb .slick-prev .fa:before {
		content: "\f104";
	}
	.module-gallery .slider-thumb .slick-next {
		right: 0;
	}
	.module-gallery .slider-thumb .slick-next .fa:before {
		content: "\f105";
	}
}