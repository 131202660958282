/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {


  .hero-slider-desktop-image {
    display: none;
  }

  .hero-slider-mobile-image {
    display: block;
    width: 100%;
  }

  .category {
    overflow-y: scroll;
    display: flex;
    justify-content: flex-start !important;
  }

  .product-category-image-banner {
    display: none;
    height: 350px;
  }

  .product-category-image-banner-responsive {
    width: 100%;
    height: auto;
    display: block;
    height: 300px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {


  .hero-slider-desktop-image {
    display: none;
  }

  .hero-slider-mobile-image {
    display: block;
    width: 100%;
  }

  .category {
    overflow-y: scroll;
    display: flex;
    justify-content: flex-start !important;
  }

  .product-category-image-banner {
    display: none;
    height: 350px;
  }

  .product-category-image-banner-responsive {
    width: 100%;
    height: auto;
    display: block;
    /* height: 300px; */
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {

  .hero-slider-desktop-image {
    display: none;
  }

  .hero-slider-mobile-image {
    display: block;
    width: 100%;
  }
  .category {
    overflow-y: scroll;
    display: flex;
    justify-content: flex-start !important;
  }
  .product-category-image-banner {
    display: none;
    height: 350px;
  }

  .product-category-image-banner-responsive {
    width: 100%;
    height: auto;
    display: block;
    height: 300px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .product-category-image-banner-responsive {
    height: 400px;
  }
}
@media(max-width:340px){
  .header-image-logo {
    width: 100%;
    height: 40px;
}
}