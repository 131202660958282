.my-profile-page {
  font-size: 30px;
  font-weight: 700;
  margin: 41px 0px 35px 50px;
  text-align: center;
}

.my-profile-personal-information-div {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.my-profile-page-head {
  font-size: 30px;
  font-weight: 400;
}

.my-profile-personal-information-text {
}

.my-profile-billing-address-div {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.my-profile-billing-address-text {
}

.my-orders-main-div {
  width: 100%;
  max-width: 1200px;
  margin: 50px auto;
}

.my-orders-heading {
  font-size: 23px;
  font-weight: 600;
}

.my-orders-heading-in {
  font-size: 23px;
  font-weight: 400;
}

.my-orders-details-main {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.my-orders-details {
  font-size: 23px;
  font-weight: 600;
}

.my-orders-details-in {
  font-size: 23px;
  font-weight: 400;
}

.my-orders-order-list{
  margin-bottom: 10px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
