.check-out-page-title {
  font-size: 30px;
  font-weight: 700;
  margin: 41px 0px 35px 50px;
  text-align: center;
}

.check-out-page-price {
  display: flex;
  justify-content: space-between;
  font-size: 25px;
  font-weight: 700;
  width: 100%;
  max-width: 1200px;
  background: #f7f7f7;
  margin: auto;
  padding: 25px;
  border-radius: 10px;
}

.check-out-page-address-main-div {
  width: 100%;
  max-width: 1200px;
  background: #f7f7f7;
  margin: 25px auto;
  padding: 25px;
  border-radius: 10px;
}

.check-out-page-billing-title {
  font-size: 30px;
  font-weight: 700;
}

.check-out-page-billing-div {
    display: flex;
    justify-content: space-between;
}

.check-out-page-billing {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.check-out-page-billing-check-button{

}

.check-out-page-text-field-row {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.check-out-page-text-field-div {
    width: 100%;
}
.paybtn {
  background-color: #52a447;
  border: none;
  margin-top: 15px;
  padding: 5px 15px;
  color: #fff;
  border-radius: 5px;
}

input#exampleFormControlInput1 {
  outline: none;
  box-shadow: none;
  border: none;
}