.main-div {
  font-family: Arial, sans-serif;
  margin: 20px;
  line-height: 1.6;
  color: #333;
}

h1,
h2,
h3 {
  color: black;
}

.highlight {
  font-weight: bold;
  color: #007bff;
}

.rules-section {
  background-color: #f8f9fa;
  padding: 10px;
  margin: 10px 0;
}

ul {
  list-style-type: disc;
  margin-bottom: 15px;
  margin-left: 20px;
}

.highlight {
  font-weight: bold;
  color: #007bff;
}

.steps,
.contact-info {
  background-color: #f8f9fa;
  padding: 10px;
  margin: 10px 0;
}
